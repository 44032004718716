/*
  * 封装axios的post请求，请求时默认加上token参数
  * 若响应状态码为401(即未登录)则删除本地存储的token并跳转至登录页面
*/

import axios from "axios";
import qs from "qs";
import store from "../store";
import router from "../router";
import { Message } from "element-ui";
export default {
  post(url, params) {
    if (!params) {
      params = { token: store.state.token };
    } else {
      params.token = store.state.token;
    }
    store.commit("changeLoading", true); // 准备发送请求时，显示loading
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/" + url + ".php",
          qs.stringify(params),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          store.commit("changeLoading", false); // 请求完成后，隐藏loading
          resolve(res.data);
        })
        .catch((err) => {
          // 登录状态失效
          reject(err.data);
          store.commit("changeLoading", false); // 请求完成后，隐藏loading
          if (err.response.status == 401) {// 登录状态失效
            store.commit("delToken");
            router.push("/login");
            router.push({
              path: "/login",
            });
          }
          // 输出错误信息
          Message({
            dangerouslyUseHTMLString: true,// 将 message 当作 HTML 片段处理
            message: err.response.data.msg,
            type: "error",
          });
        });
    });
  },
};
