//该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//引入初始化好的router
import router from '../router'
//应用Vuex插件
Vue.use(Vuex)
//创建并暴露store
export default new Vuex.Store({
	actions:{
	},
	mutations:{
		//改变侧边栏状态
		changeCollapse(context){
			context.isCollapse = !context.isCollapse
		},
		//添加tag
		addTag(context,menu){
			context.tagList.indexOf(menu)==-1?context.tagList.push(menu):null
		},
		//关闭tag
		closeTag(context,menu){
			let index = context.tagList.indexOf(menu)
			context.tagList.splice(index,1)
			if(index==context.tagList.length){
				index --
			}
			//如果关闭的是当前页面，则跳转到相邻标签，优先右侧
			if(router.app._route.name==menu.name){
				router.push({
					path: '/home'+context.tagList[index].path
				})
			}
		},
		//点击选择tag
		selectTag(context,menu){
			let index = context.tagList.indexOf(menu)
			router.push({
				path: '/home'+context.tagList[index].path
			})
		},
		changeLoading(context,status){
			context.loading = status
		},
		// 设置token
		setToken(context,token){
			context.token = token;
			localStorage.setItem('token', token);
		},
		// 删除token
		delToken(context){
			context.token = ''
			localStorage.removeItem('token');
		},
		// 设置用户昵称
		setNickname(context,nickname){
			context.nickname = nickname
		},
		// 删除用户昵称
		delNickname(context){
			context.nickname = ''
		},
		// 退出登录
		logout(context){
			context.token = ''
			context.nickname = ''
			localStorage.removeItem('token');
		},
	},
	state:{
		isCollapse: false,//侧边栏状态
		tagList:[
			{
				name:'home',
				path:'',
				title:'竹水云代步',
			}
		],//存储头部标签列表
		loading:false,//加载状态
		token:'',//存储token
		nickname: '',//存储用户昵称
	},
	getters:{
	},
})