//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
//引入store
import store from './store'
//引入ElementUI组件库
import ElementUI from 'element-ui';
// 引入粒子特效
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
//引入ElementUI全部样式
import 'element-ui/lib/theme-chalk/index.css';
//关闭Vue的生产提示
Vue.config.productionTip = false
//应用插件
Vue.use(VueRouter)
//应用ElementUI
Vue.use(ElementUI);
//创建vm
new Vue({
	el:'#app',
	router,
	store,
	render: h => h(App)
})
