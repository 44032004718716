<template>
    <router-view></router-view>
</template>

<script> 
	export default {
		name:'App',
    components:{},
    data(){
      return {
      }
    },
    computed:{
      
    },
    mounted(){
      
    },
    methods:{
      
    },
    
	}
</script>
<style lang="less">
html, body, .el-container{
    height: 100%;
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
}
// ----------修改elementui表格滚动条的默认样式-----------
.el-table__body-wrapper{
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #1e9fff;
  }
}

// ----------修改elementui question图标的颜色-----------
.el-icon-question{
  color: #e6a23c !important;
}
</style>