import request from "../request";
const user = {
  // 用户登录
  login(params) {
    return request.post("login", params);
  },
  // 用户注册
  register(params) {
    return request.post("register", params);
  },
  // 用户退出
  logout() {
    return request.post("logout");
  },
  // 获取用户信息
  getUserInfo() {
    return request.post("getuserinfo");
  },
  // 更改密码
  repass(params) {
    return request.post("repass", params);
  }
};
export { user };

const home = {
  // 获取首页数据
  getHomeData() {
    return request.post("gethomedata");
  }
}
export { home };

const relation ={
  get(params) {
    params.type = "get"
    return request.post("relation", params);
  },
  add(params) {
    params.type = "add";
    return request.post("relation", params);
  },
  del(params) {
    params.type = "del";
    return request.post("relation", params);
  },
  update(params) {
    params.type = "update";
    return request.post("relation", params);
  },
  change(params) {
    params.type = "change";
    return request.post("relation", params);
  },
  submit(params) {
    return request.post("submit", params);
  }
}
export { relation };

const log = {
  // 获取日志列表
  get(params) {
    return request.post("getlog", params);
  }
}
export { log };