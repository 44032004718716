// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
// 引入初始化好的store
import store from "../store";
// 引入user接口
import { user } from "../api";
// 引入element-ui的Message组件
import { Message } from "element-ui";
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//创建并暴露一个路由器
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "home",
      path: "/home",
      component: () => import("../pages/Home"),
      meta: {
        title: "首页",
        name: "home",
        path: "/",
        isRequired: true,
      },
      children: [
        {
          name: "relation",
          path: "/home/relation",
          component: () => import("../pages/Relation"),
          meta: {
            title: "关联账号",
            name: "relation",
            path: "/relation",
            isRequired: true,
          },
        },
        {
          name: "log",
          path: "/home/log",
          component: () => import("../pages/Log"),
          meta: {
            title: "运行日志",
            name: "log",
            path: "/log",
            isRequired: true,
          },
        },
        {
          name: "repass",
          path: "/home/repass",
          component: () => import("../pages/rePass"),
          meta: {
            title: "更改密码",
            name: "repass",
            path: "/repass",
            isRequired: true,
          },
        },
      ],
    },
    {
      name: "login",
      path: "/login",
      component: () => import("../pages/Login"),
      meta: { title: "用户登录" },
    },
    {
      name: "register",
      path: "/register",
      component: () => import("../pages/Register"),
      meta: { title: "用户注册" },
    },
    {
      path: "*",
      redirect: "/home",
    },
  ],
});
router.beforeEach((to, from, next) => {
  // 将localStorage中token存入Vuex中(方便用户进行各种操作时验证是否登录)
  if (localStorage.getItem("token")) {
    store.commit("setToken", localStorage.getItem("token"));
  }
  if (store.state.token) {
    // 若Vuex中有token，验证token合法性
    user.getUserInfo().then((res) => {
      store.commit("setNickname", res.nickname);

      if (res.code == 1) {
        // token合法，页面正常跳转
        if (to.meta.isRequired) {
          next();
        } else {
          Message({
            message: "您已登录",
            type: "success",
            duration: 1500,
          });
          next({ name: "home" });
        }
      } else {
        // token不合法，删除Vuex以及localStorage中的token，跳转到登录页面
        store.commit("delToken");
        if (to.meta.isRequired) {
          Message({
            message: res.msg,
            type: "error",
            duration: 1500,
          });
          next({ name: "login" });
        } else {
          next();
        }
      }
    });
  } else {
    // Vuex中没有token，则跳转到登录页面
    if (to.meta.isRequired) {
      Message({
        message: "请先登录",
        type: "error",
        duration: 1500,
      });
      next({ name: "login" });
    } else {
      next();
    }
  }
});
router.afterEach((to) => {
  //设置title
  document.title = "竹水云代步 - " + to.meta.title;
  if (to.meta.isRequired && to.name !== "home") {
    store.commit("addTag", to.meta);
  }
});
export default router;
